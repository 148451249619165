<template>
    <section>
        <div class="calendar__info">
        <h3 class="calendar__title">Calendar</h3>
            <button class="calendar__teammates" @click="showTeamMatesModal = !showTeamMatesModal">
            Teammates
            <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.59749 4.33916C3.81979 4.55367 4.18021 4.55367 4.40251 4.33916L7.58213 1.27104C7.94073 0.925019 7.68676 0.333374 7.17962 0.333374H0.820377C0.313243 0.333374 0.0592681 0.925018 0.417866 1.27104L3.59749 4.33916Z" fill="#606569"/>
            </svg>
            </button>
            <div class="calendar__calendar">
            <p class="calendar__calendar-title" @click="showCalendarModal = !showCalendarModal">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.66667 0.833344V4.16668M12.3333 0.833344V4.16668M16.5 7.50001H1.5M4.83333 10.8333H5.66667M8.58333 10.8333H9.41667M12.3333 10.8333H13.1667M4.83333 14.1667H9.41667M12.3333 14.1667H13.1667M3.16667 17.5H14.8333C15.7054 17.5 16.5 16.6701 16.5 15.8333V4.16668C16.5 3.32988 15.7931 2.50001 14.9211 2.50001H3.16667C2.29464 2.50001 1.5 3.32988 1.5 4.16668V15.8333C1.5 16.6701 2.29464 17.5 3.16667 17.5Z" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>
                Calendar
                </span>
            </p>
            <button class="calendar__calendar-arrow calendar__calendar-arrow--left">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 15.8334L5.83334 10L12.5 4.16669" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <button class="calendar__calendar-arrow">
                <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.50002 12.8333L8.16669 6.99999L1.50002 1.16666" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            </div>
        </div>
        <TeamMatesModal  v-if="showTeamMatesModal"  @close="showTeamMatesModal = false"/>
        <CalendarModal  v-if="showCalendarModal"  @close="showCalendarModal = false"/>
    </section>
</template>

<script>
    import TeamMatesModal from '@/components/Modal/TeamMatesModal'
    import CalendarModal from '@/components/Modal/CalendarModal'


    export default {
        data() {
            return {
                showTeamMatesModal: false,
                largePadding: true,
                showCalendarModal: false,
            }
        },
        components: {
            TeamMatesModal, CalendarModal,
        },
        methods:{
        }
    }
</script>

<style scoped>
    button{
        border: none;
        background: none;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        background: #fff;
    }
    
    .calendar__teammates{
        width: 149px;
        height: 42px;
        border-radius:30px;
        border: 2px solid #F2F3F6;
        margin-left: auto;
    }
    
    .calendar__calendar{
        width: 255px;
        height: 42px;
        border-radius:30px;
        border: 2px solid #F2F3F6;
    }

    .calendar__info{
        display: flex;
        flex-direction: row;
        padding: 20px 30px;
    }

    .calendar__title{
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
        margin: 0;
    }

    .calendar__teammates{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
    }

    .calendar__teammates svg{
        padding-bottom: 3px;
        padding-left: 3px;
    }

    .calendar__calendar{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px;
        background: #fff;
        box-sizing: border-box;
        margin-left: 20px;
    }

    .calendar__calendar-title{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .calendar__calendar-title span{
        padding-left: 12px;
        font-size: 14px;
        font-weight: 500;
    }

    .calendar__calendar-arrow--left{
        margin-left: auto;
    }

    @media(max-width: 970px){
        .calendar__title{
            width: 100%;
            padding-bottom: 5px;
        }

        .calendar__info{
            flex-wrap: wrap;
        }
        
    }

    @media(max-width: 805px){

        .calendar__teammates{
            margin-bottom: 5px;
            margin-right: 20px;
        }
        
        .calendar__calendar{
            margin-left: 0;
        }
    }

</style>