<template>
  <div>
    <CalendarInfo />
    <CalendarWrp />
  </div>
</template>

<script>
    import CalendarInfo from '@/components/Calendar/CalendarInfo'
    import CalendarWrp from '@/components/Calendar/CalendarWrp'


    export default {
        components: {
          CalendarInfo, CalendarWrp
        }
    }
</script>
