<template>
    <section>
        <table>
            <thead>
                <tr>
                    <th> 
                        <span class="calendar__minus"> - </span>
                        <span class="calendar__plus" v-on:click="showCreateNewModal = !showCreateNewModal"> + </span> 
                    </th>
                    <th> <p>Mon. Apr 19</p>  <span>00:00:00</span></th>                    
                    <th> <p>Tue. Apr 20</p>  <span>00:00:00</span></th>
                    <th> <p>Wed. Apr 21</p>  <span>00:00:00</span></th>
                    <th> <p>Thu. Apr 22 </p>  <span>00:00:00</span></th>
                    <th> <p>Fri. Apr 23</p>  <span>00:00:00</span></th>
                    <th> <p>Sat. Apr 24</p>  <span>00:00:00</span></th>
                    <th> <p>Sun. Apr 25</p>  <span>00:00:00</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>01:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>02:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>03:00</td>
                    <td></td>
                    <td rowspan="3" class="calendar__td-project">
                        <div class="calendar__project calendar__project--small"  v-on:click="showAddTimeEntryModal = !showAddTimeEntryModal">
                            <p class="calendar__title">Prototype</p>
                            <h5 class="calendar__h5">Weblider. The main. Testimonials</h5>
                            <p class="calendar__status">Performed</p>
                            <div class="calendar__links">
                                <p class="calendar__tags">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.875 9.78423L14.5135 10.4185L13.875 9.78423ZM9.8155 13.8706L9.17701 13.2363H9.17701L9.8155 13.8706ZM6.56788 13.8706L7.20637 13.2363H7.20637L6.56788 13.8706ZM13.875 6.51514L14.5135 5.88085V5.88085L13.875 6.51514ZM9.05889 1.66717L9.69737 1.03288V1.03288L9.05889 1.66717ZM1.66378 8.93408L1.0253 9.56838H1.0253L1.66378 8.93408ZM13.2365 9.14993L9.17701 13.2363L10.454 14.5049L14.5135 10.4185L13.2365 9.14993ZM9.17701 13.2363C8.63215 13.7847 7.75123 13.7847 7.20637 13.2363L5.92939 14.5049C7.17814 15.7619 9.20524 15.7619 10.454 14.5049L9.17701 13.2363ZM13.2365 7.14944C13.7847 7.70119 13.7847 8.59819 13.2365 9.14993L14.5135 10.4185C15.759 9.16481 15.759 7.13457 14.5135 5.88085L13.2365 7.14944ZM8.4204 2.30147L13.2365 7.14944L14.5135 5.88085L9.69737 1.03288L8.4204 2.30147ZM8.54276 0.552393H2.54366V2.35239H8.54276V0.552393ZM7.20637 13.2363L2.30227 8.29979L1.0253 9.56838L5.92939 14.5049L7.20637 13.2363ZM0.552393 2.54366V8.42135H2.35239V2.54366H0.552393ZM2.30227 8.29979C2.33438 8.3321 2.35239 8.3758 2.35239 8.42135H0.552393C0.552393 8.85114 0.722394 9.26348 1.0253 9.56838L2.30227 8.29979ZM2.54366 0.552393C1.44392 0.552393 0.552393 1.44391 0.552393 2.54366H2.35239C2.35239 2.43803 2.43803 2.35239 2.54366 2.35239V0.552393ZM9.69737 1.03288C9.39185 0.725337 8.97626 0.552393 8.54276 0.552393V2.35239C8.49682 2.35239 8.45278 2.33406 8.4204 2.30147L9.69737 1.03288ZM6.44525 5.70835C6.44525 6.11533 6.11533 6.44525 5.70835 6.44525V8.24525C7.10944 8.24525 8.24525 7.10944 8.24525 5.70835H6.44525ZM5.70835 6.44525C5.30136 6.44525 4.97144 6.11533 4.97144 5.70835H3.17144C3.17144 7.10944 4.30725 8.24525 5.70835 8.24525V6.44525ZM4.97144 5.70835C4.97144 5.30136 5.30136 4.97144 5.70835 4.97144V3.17144C4.30725 3.17144 3.17144 4.30725 3.17144 5.70835H4.97144ZM5.70835 4.97144C6.11533 4.97144 6.44525 5.30136 6.44525 5.70835H8.24525C8.24525 4.30725 7.10944 3.17144 5.70835 3.17144V4.97144Z" fill="#222222"/>
                                    </svg>
                                    <span class="calendar__tags-list">Prototype, Design, Text</span>
                                </p>
                                <p class="calendar__money">
                                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.27385 3.41666H3.36314C2.09749 3.41666 1.07147 4.44267 1.07147 5.70832C1.07147 6.97397 2.09749 7.99999 3.36314 7.99999H6.63695C7.9026 7.99999 8.92862 9.026 8.92862 10.2917C8.92862 11.5573 7.9026 12.5833 6.63695 12.5833H1.72623M5.00004 0.797607V15.2024" stroke="#222222" stroke-width="1.8" stroke-linecap="round"/>
                                    </svg>
                                </p>
                                <span class="calendar__time">03:00:00</span>
                            </div>
                        </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>04:00</td>
                    <td></td>
                    <td rowspan="7" class="calendar__td-project">
                        <div class="calendar__project calendar__project--big" v-on:click="showAddTimeEntryModal = !showAddTimeEntryModal">
                            <p class="calendar__title">Prototype</p>
                            <h5 class="calendar__h5">Weblider. The main. Testimonials</h5>
                            <p class="calendar__status">Performed</p>
                            <div class="calendar__links">
                                <p class="calendar__tags">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.875 9.78423L14.5135 10.4185L13.875 9.78423ZM9.8155 13.8706L9.17701 13.2363H9.17701L9.8155 13.8706ZM6.56788 13.8706L7.20637 13.2363H7.20637L6.56788 13.8706ZM13.875 6.51514L14.5135 5.88085V5.88085L13.875 6.51514ZM9.05889 1.66717L9.69737 1.03288V1.03288L9.05889 1.66717ZM1.66378 8.93408L1.0253 9.56838H1.0253L1.66378 8.93408ZM13.2365 9.14993L9.17701 13.2363L10.454 14.5049L14.5135 10.4185L13.2365 9.14993ZM9.17701 13.2363C8.63215 13.7847 7.75123 13.7847 7.20637 13.2363L5.92939 14.5049C7.17814 15.7619 9.20524 15.7619 10.454 14.5049L9.17701 13.2363ZM13.2365 7.14944C13.7847 7.70119 13.7847 8.59819 13.2365 9.14993L14.5135 10.4185C15.759 9.16481 15.759 7.13457 14.5135 5.88085L13.2365 7.14944ZM8.4204 2.30147L13.2365 7.14944L14.5135 5.88085L9.69737 1.03288L8.4204 2.30147ZM8.54276 0.552393H2.54366V2.35239H8.54276V0.552393ZM7.20637 13.2363L2.30227 8.29979L1.0253 9.56838L5.92939 14.5049L7.20637 13.2363ZM0.552393 2.54366V8.42135H2.35239V2.54366H0.552393ZM2.30227 8.29979C2.33438 8.3321 2.35239 8.3758 2.35239 8.42135H0.552393C0.552393 8.85114 0.722394 9.26348 1.0253 9.56838L2.30227 8.29979ZM2.54366 0.552393C1.44392 0.552393 0.552393 1.44391 0.552393 2.54366H2.35239C2.35239 2.43803 2.43803 2.35239 2.54366 2.35239V0.552393ZM9.69737 1.03288C9.39185 0.725337 8.97626 0.552393 8.54276 0.552393V2.35239C8.49682 2.35239 8.45278 2.33406 8.4204 2.30147L9.69737 1.03288ZM6.44525 5.70835C6.44525 6.11533 6.11533 6.44525 5.70835 6.44525V8.24525C7.10944 8.24525 8.24525 7.10944 8.24525 5.70835H6.44525ZM5.70835 6.44525C5.30136 6.44525 4.97144 6.11533 4.97144 5.70835H3.17144C3.17144 7.10944 4.30725 8.24525 5.70835 8.24525V6.44525ZM4.97144 5.70835C4.97144 5.30136 5.30136 4.97144 5.70835 4.97144V3.17144C4.30725 3.17144 3.17144 4.30725 3.17144 5.70835H4.97144ZM5.70835 4.97144C6.11533 4.97144 6.44525 5.30136 6.44525 5.70835H8.24525C8.24525 4.30725 7.10944 3.17144 5.70835 3.17144V4.97144Z" fill="#222222"/>
                                    </svg>
                                    <span class="calendar__tags-list">Prototype, Design, Text</span>
                                </p>
                                <p class="calendar__money">
                                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.27385 3.41666H3.36314C2.09749 3.41666 1.07147 4.44267 1.07147 5.70832C1.07147 6.97397 2.09749 7.99999 3.36314 7.99999H6.63695C7.9026 7.99999 8.92862 9.026 8.92862 10.2917C8.92862 11.5573 7.9026 12.5833 6.63695 12.5833H1.72623M5.00004 0.797607V15.2024" stroke="#222222" stroke-width="1.8" stroke-linecap="round"/>
                                    </svg>
                                </p>
                                <span class="calendar__time">07:00:00</span>
                            </div>
                        </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>05:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                 
                </tr>
                <tr>
                    <td>06:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>07:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>08:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>09:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>10:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>11:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>12:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>13:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>14:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>15:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>16:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>17:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>18:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>19:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>20:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>21:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>22:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>23:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
                <tr>
                    <td>24:00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                </tr>
            </tbody>
        </table>
        <div class="bg" v-if="showCreateNewModal" @close="showCreateNewModal = false"></div>
        <CreateNewModal v-if="showCreateNewModal" @close="showCreateNewModal = false"/>
        <div class="bg" v-if="showAddTimeEntryModal" @close="showAddTimeEntryModal = false"></div>
        <AddTimeEntryModal v-if="showAddTimeEntryModal" @close="showAddTimeEntryModal = false" />
    </section>
</template>

<script>
    import CreateNewModal from '@/components/Modal/CreateNewModal'
    import AddTimeEntryModal from '@/components/Modal/AddTimeEntryModal'

    export default {
        data() {
            return {
                showCreateNewModal: false,
                showAddTimeEntryModal: false
            }
        },
        components: {
            CreateNewModal, AddTimeEntryModal
        }
    }

</script>

<style scoped>

    .bg{
        width: 100vw;
        height: 100vh;
        background-color: #3d3d3d;
        opacity: 0.5;
        z-index:  14;
        position: fixed;
        top: 0;
        left: 0;
    }

    p{
        margin: 0;
        padding: 0;
    }
    section{
        margin: 10px 30px;
        height: 78vh;
        overflow: auto;
    }

    table{
        width: 100%;
        border-spacing: 0px;
        border-collapse: collapse;
        background-color: #fff;
        min-width: 1300px;
    }

    th{
        border: 2px solid #E7E7E8;
    }

    td{
        padding: 20px;
        border: 2px solid #E7E7E8;
        box-sizing: border-box;
        width: calc(100% / 7);
    }

    td:first-child{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        width: 80px;
    }

    th{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding: 10px 0;
    }

    th p{
        padding-bottom: 7px;
    }

    th:first-child{
        font-size: 36px;
        font-weight: 400;
    }

    .calendar__plus:hover{
        color: #9CEDFF;
        cursor: pointer;
    }

    .calendar__td-project{
        padding: 0;
    }

    .calendar__project{
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }

    .calendar__title{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }

    .calendar__project--small{
        background: linear-gradient(0deg, rgba(159, 157, 255, 0.1), rgba(159, 157, 255, 0.1)),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
        border-left: 5px solid #9F9DFF;
        height: 175px;
    }

    .calendar__project--small .calendar__title{
        color: #9F9DFF;
    }

    .calendar__links{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-grow: 1;
    }
    
    .calendar__h5{
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
        margin: 0;
        margin-top: 10px;
    }

    .calendar__status{
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        margin-top: 10px;
        padding-left: 15px;
        position: relative;
    }

    .calendar__status:before{
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #58C7E0;
        left: 0;
        top: 2px;
    }

    .calendar__tags{
        position: relative;
        height: 27px;
        width: 27px;
        border-radius: 50%;
        border: 1.5px solid #222222;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .calendar__tags span{
        position: absolute;
        top: 30px;
        left: 10px;
        width: 184px;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        background-color: #fff;
        height: 17px;
        display: none;
    }

    .calendar__tags:hover span{
        display: inline-block;
    }

    .calendar__money{
        height: 27px;
        width: 27px;
        border-radius: 50%;
        border: 1.5px solid #222222;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .calendar__time{
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        color: #606569;
        margin-left: auto
    }

    .calendar__project--big{
        height: 411px;
        background-color: #EEF9FC;
        border-left: 5px solid #58C7E0;
    }

    .calendar__project--big .calendar__title{
        color: #58C7E0;
    }

</style>