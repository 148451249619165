<template>
        <div class="add-entry" v-click-outside="onClickOutside">
            <h3 class="add-entry--title">
                Add time entry
            </h3>
            <div class="add-entry__wrp">
                <form class="add-entry__form">
                    <button href="" class="close_X" @click="$emit('close')">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.33301 14.6667L7.99967 7.99999M14.6663 1.33333L7.99967 7.99999M7.99967 7.99999L14.6663 14.6667M7.99967 7.99999L1.33301 1.33333" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <div class="add-entry__item">
                        <p class="add-entry__time">
                            <label class="add-entry__time-label">
                                Add time entry
                            </label>
                        <input class="add-entry__time-input" type="text" placeholder="00:00:00" pattern="\b[0-2]?\d:[0-5]\d\b:[0-5]\d\b">
                        </p>
                        <input class="add-entry__input-begin add-entry__dead-time" type="time" name="" id="">
                        <input class="add-entry__dead-time add-entry__input-end" type="time" name="" id="">
                        <svg class="add-entry__svg" width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 1V5M14 1V5M19 9H1M5 13H6M9.5 13H10.5M14 13H15M5 17H10.5M14 17H15M3 21H17C18.0464 21 19 20.0042 19 19V5C19 3.99585 18.1517 3 17.1053 3H3C1.95357 3 1 3.99585 1 5V19C1 20.0042 1.95357 21 3 21Z" stroke="#BABCBF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <input class="add-entry__dead-date add-entry__input-time" type="date" name="" id="">
                    </div>
                    <p class="add-entry__description">
                        <label for="">Description</label>
                        <textarea name="" id="" cols="30" rows="10"></textarea>
                    </p>
                    <div class="add-entry__tags">
                        <label for="">Project</label>
                        <p class="add-entry__project"  @click="showSelectModal = !showSelectModal"></p>
                        <SelectModal  v-if="showSelectModal"  @close="showSelectModal = false" @openMenu="showCreateNewModal = true" />
                    </div>
                    <p class="add-entry__tags">
                        <label for="">Tags</label>
                        <TagSelectModal />
                    </p>
                    <p class="add-entry__checkboxes">
                        <input class="billable" type="checkbox" name="" id="billable"><label for="billable" class="billable__label">Billable</label>
                    </p>
                    <p class="add-entry__tags">
                        <label for="">Client</label>
                        <v-selectize v-model="selectedC" :options="['Client 1','Client 2', 'Client 3']"/>
                    </p>
                    <div class="add-entry__item add-entry__dead">
                        <p class="add-entry__time">
                            <label class="add-entry__time-label__dead" for="">
                                Deadline
                            </label>
                        </p>
                        <p class="add-entry__dead-input">
                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 1V5M14 1V5M19 9H1M5 13H6M9.5 13H10.5M14 13H15M5 17H10.5M14 17H15M3 21H17C18.0464 21 19 20.0042 19 19V5C19 3.99585 18.1517 3 17.1053 3H3C1.95357 3 1 3.99585 1 5V19C1 20.0042 1.95357 21 3 21Z" stroke="#BABCBF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <input class="add-entry__dead-date" type="date" name="" id="">
                            <input class="add-entry__dead-time add-entry__dead-time--bottom" type="time" name="" id="">
                        </p>
                    </div>
                    <div class="buttons">
                        <button class="add-entry__start">
                            Start
                        </button>
                        <button @click="$emit('close')">
                            Cancel
                        </button>
                    </div>
                </form>
                <div class="add-entry__right">
                    <div class="add-entry__comments">
                        <p class="add-entry__comment">Some kind of notification</p>
                        <p class="add-entry__comment"> <span>You</span> "some kind of notification"</p>
                        <p class="add-entry__comment"> <span>You</span> “Some kind of long notification occupying two lines with links <span>Link</span> and <span>link 2</span>”</p>
                        <div class="add-entry__comment-item">
                            <p class="comment__logo">
                                AF
                            </p>
                            <div class="comment__item">
                                <p class="comment__title">
                                    <span>You</span> commentes
                                </p>
                                <p class="comment__txt">
                                    What are you working on?
                                </p>
                                <span class="comment__date">27.05</span>
                            </div>
                        </div>
                        <div class="add-entry__comment-item partner">
                            <p class="comment__logo comment__logo--purple">
                                MD
                            </p>
                            <div class="comment__item">
                                <p class="comment__title">
                                    <span>Maria D</span> commentes
                                </p>
                                <p class="comment__txt">
                                    What are you working on?
                                </p>
                                <span class="comment__date">27.05</span>
                            </div>
                        </div>
                        <div class="add-entry__comment-item partner">
                            <p class="comment__logo comment-logo--sand">
                                RH
                            </p>
                            <div class="comment__item">
                                <p class="comment__title">
                                    <span>Roman H</span> commentes
                                </p>
                                <p class="comment__txt">
                                    Some kind of long notification 
                                </p>
                                <span class="comment__date">27.05</span>
                            </div>
                        </div>
                    </div>
                    <form class="add-entry__comments-form">
                        <input name="file" type="file" id="upload_presentation" class="input input__file" multiple>
						<label for="upload_presentation" class="input__presentation-button">
                            <img src="@/assets/img/skrepka.png" alt="">
						</label>
                        <input class="add-entry__comments-input" type="text" placeholder="Comment or type “/” for commands">
                        <button class="add-entry__comments-btn">Send</button>
                    </form>
                </div>
            </div>
            <CreateNewModal class="add-entry__position" v-if="showCreateNewModal" @close="showCreateNewModal = false"/>
            <div class="bg" v-if="showCreateNewModal" @close="showCreateNewModal = false"></div>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import 'selectize/dist/css/selectize.default.css'
    import VSelectize from '@isneezy/vue-selectize'
    import SelectModal from '@/components/Modal/SelectModal'
    import TagSelectModal from '@/components/Modal/TagSelectModal'
    import CreateNewModal from '@/components/Modal/CreateNewModal'



    
    export default {
        data() {
            return {
                showCreateNewModal: false,
                showSelectModal: false,
                selectedT: 'All tags',
                selectedC: 'Client 1',
                selectedP: 'Project',
            }
        },
        directives: {
        clickOutside: vClickOutside.directive
        },
        methods: {
        onClickOutside () {
            this.$emit('close')
        }
        },
        components: {
            VSelectize, SelectModal, CreateNewModal, TagSelectModal
        }
            };
</script>

<style scoped>
    p{
        margin: 0;
        padding: 0;
    }
    .add-entry{
        width: 1098px;
        height: 799px;
        background-color: #fff;
        position: fixed;
        top: 5%;
        padding: 20px;
        color: #222222;
        box-sizing: border-box;
        margin-bottom: 50px;
        z-index: 20;
        right: calc( 50vw - 550px);
        margin-left: -273px;
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
    }

    .add-entry--title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0;
        padding-bottom: 25px;
        border-bottom: 2px solid #F2F3F6;
    }
    
    .add-entry__wrp{
        display: flex ;
        flex-direction: row;
        width: 100%;
    }

    input:invalid{
        border: 1px solid red;
    }

    .add-entry__form{
        width: calc(50% - 10px);
    }

    .add-entry__time{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .add-entry__time-input{
        width: 120px;
    }

    .add-entry__item{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 15px 0;
        border-bottom: 2px solid #F2F3F6;
    }

    .add-entry__svg{
            width: 37px;
            height: 20px;
            margin: 0 0px 11px 15px;
    }

    .add-entry__time-label{
        padding-bottom: 5px;
    }

    .add-entry__right{
        margin-left: 20px;
        width: calc(50% - 10px);
    }

    .add-entry__comments{
        background: rgba(242, 243, 246, 0.2);
        border: 2px solid #F2F3F6;
        border-top: 0;
        border-bottom: 0;
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
        height: calc( 100% - 84px );
    }

    .add-entry__comment{
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        color: #BABCBF;
        margin-bottom: 10px;
    }

    .add-entry__comment span{
        cursor: pointer;
        color:  #58C7E0;
        font-weight: 600;
    }

    .add-entry__description label,
    .add-entry__tags label{
        margin-bottom: 5px;
    }



    .add-entry__tags select,
    .add-entry__time input{
        margin-top: 5px;
        border-radius: 30px;
        max-width: 449px;
        height: 42px;
        border: 2px solid #F2F3F6;
        font-family: Montserrat;
        outline: none;
        padding: 0 15px;
        box-sizing: border-box;
    }

    .add-entry__tags select{
        width: 100%;
        max-width: 100%;
    }


    .add-entry__color{
        align-self: flex-end;
        margin-bottom: 25px;
        cursor: pointer;
        margin-right: 10px;
    }

    .add-entry__color input{
        border: none;
        background: #fff;
        width: 26px;
        height: 30px;
        border-radius: 50%;
        padding: 0;
        margin-right: 5px;
        cursor: pointer;
    }

    input[type="color"]::-webkit-color-swatch {
        border: none;  
        border-radius: 50%;
    }

    input[type=color]::-moz-focus-inner {
        border: none;
        padding: 0;
        border-radius: 50%;
    }

    input[type=color]::-moz-color-swatch {
        border: none;
        border-radius: 50%;
        height: 30px;
    }

    .add-entry__color svg{
        padding-bottom: 8px;
    }

    .add-entry__tags,
    .add-entry__description{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-top: 20px;
    }

    .add-entry__tags label,
    .add-entry__description label{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding-bottom: 5px;
    }

    .add-entry__description textarea{
        width: 100%;
        height:75px;
        border: 2px solid #F2F3F6;
        border-radius: 4px;
        outline: none;
        box-sizing: border-box;
    }

    .add-entry__tags select{
        font-weight: 600;
        appearance: none;
        -moz-appearance: none;
    }

    .add-entry__tags{
            position: relative;
    }
    

    .add-entry__tags > select::-ms-expand{
        display: none;
    }

    .add-entry__tags label{
        position: relative;
    }

    .add-entry__tags label:after{
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-image: url('../../assets/img/Rectangle.svg');
        background-repeat: no-repeat;
        background-position: center;
        bottom: -32px;
        right: 16px;
        cursor: pointer;
        background-size: contain;
    }

    .add-entry__checkboxes{
        display: flex;
        flex-direction: column;
    }

    .add-entry__checkboxes input{
        display: none;
    }

    .add-entry__checkboxes label{
        position: relative;
        width: 36px;
        height: 20px;
        margin: 15px 0 0 0;
        padding-left: 50px;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }

    .add-entry__checkboxes label:after{
        position: absolute;
        content: '';
        width: 36px;
        height: 20px;
        background-image: url('../../assets/img/off.png');
        top: -2px;
        left: 0;
    }

    .public:checked ~ .public__label:after{
        background-image: url('../../assets/img/on.png');
    }

    .billable:checked ~ .billable__label:after{
        background-image: url('../../assets/img/on.png');
    }

    .add-entry__dead{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
    }

    .add-entry__dead-input{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
    }

    .add-entry__dead-date{
        margin-left: 10px;
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 30px;
        width: 150px;
        height: 42px;
        padding: 0 10px;
        outline: none;
        font-family: Montserrat;
        color: #222222;
    }

    .add-entry__dead-time{
        margin-left: 10px;
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 30px;
        width: 110px;
        padding: 0 10px;
        outline: none;
        height: 42px;
        font-family: Montserrat;
        color: #222222;
    }

    .add-entry__time-label__dead{
        padding-bottom: 15px;
    }

    button{
        border: none;
        background: none;
        cursor: pointer;
    }

    .buttons{
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        padding-bottom: 23px;
    }

    .buttons button{
        margin-right: 10px;
        margin-top: 10px;
        width: 107px;
        height: 42px;
        font-family: 'Montserrat', sans-serif;
        background: #9CEDFF1A;
        border: 2px solid #9CEDFF;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .buttons .add-entry__start{
        background-color: #9CEDFF;
    }

    @media(max-width:800px){
        .bg{
            left: 0;
        }
    }

    
    @media(max-width:600px){
        .add-entry{
            width: 100vw;
            margin-left: -75px;
            right: 0;
            box-sizing: border-box;
        }
    }

    .close_X{
        position: absolute;
        right: 19px;
        top: 23px;
    }

    .add-entry__input-begin{
        border-radius: 30px 0 0 30px;
    }

    .add-entry__input-end{
        border-radius:  0 30px 30px 0;
        margin-left: 0;
        border-left: 0;
    }

    .add-entry__comment-item{
        display: flex;
        flex-direction: row-reverse;
    }

    .add-entry__comment-item.partner{
        flex-direction: row;
    }

    .comment__logo{
        width: 30px;
        height: 30px;
        background: #9CEDFF;
        border-radius: 50%;
        font-size: 12px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        margin-left: 10px;
        cursor: pointer;
    }

    .partner .comment__logo{
        margin-left: 0;
        margin-right:  10px;
    }

    .comment__item{
        flex-grow: 1;
        border: 2px solid #F2F3F6;
        background-color: #fff;
        padding: 12px;
        position: relative;
        margin-bottom: 10px;
        max-width: 439px;

    }

    .comment__title{
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        color: #BABCBF;
    }

    .comment__title span{
        color: #58C7E0;
        padding-right: 3px;
    }

    .comment__txt{
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        color: #BABCBF;
        padding-top: 5px;
        max-width: 407px;
    }

    .comment__date{
        position: absolute;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        color: #BABCBF;
        top: 10px;
        right: 12px;
    }

    .comment__logo--purple{
        background: #9F9DFF;
    }

    .comment-logo--sand{
        background: #D2B44A;
    }

    .input__file {
        opacity: 0;
        visibility: hidden;
        position: absolute;
    }

    .input__presentation-button{
        width: 24px;
        height: 24px;
        border-radius: 5px;
        cursor: pointer;
    }

    .add-entry__comments-input{
        width: 348px;
        height: 39px;
        border: 2px solid #E7E7E8;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 10px 20px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        outline: none;
    }

    .add-entry__comments-btn{
        background-color: #9CEDFF;
        width: 89px;
        height: 39px;
        color: #222222;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        border-radius: 30px;
    }

    .add-entry__comments-form{
        border-left:  2px solid #F2F3F6;
        border-right:  2px solid #F2F3F6;
        height: 84px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 0 10px;
    }

    .add-entry__project{
        width: 100%;
        height: 42px;
        border: 2px solid #F2F3F6;
        border-radius: 30px;
        cursor: pointer;
        box-sizing: border-box;
    }

    .add-entry__position{
        margin-left: 0;
        right: 0;
        left: 25%;
    }

    @media(max-width: 1350px) {
        .add-entry{
            right: calc( 50vw - 548px);
        }
    }

    @media(max-width: 1098px){
        .add-entry{
            width: 100%;
            right: 0;
        }
    }

    @media(max-width: 800px){
        .add-entry{
            height: 92vh;
            padding-bottom: 60px;
            overflow-y: auto;
        }

        .add-entry__wrp{
            flex-wrap: wrap;
        }

        .add-entry__form{
            width: 100%;
        }

        .add-entry__right{
            width: 100%;
            margin-left: 0;
        }

        .add-entry__comments{
            border-top: 2px solid #F2F3F6;
        }
    }

    @media(max-width:560px){
        .add-entry__comments-form{
            flex-wrap: wrap;
            height: auto;
            background-color: #fff;
        }

        .add-entry__comments-input,
        .add-entry__comments-btn,
        .input__presentation-button{
            margin: 5px 0;
        }

        .add-entry__item{
            flex-wrap: wrap;
        }

        .add-entry__time{
            width: 100%;
        }

        .add-entry__input-begin{
            margin-left: 0;
            margin-top: 10px;
        }

        .add-entry__input-end{
            margin-left: 0;
            margin-top: 10px;
        }

        .add-entry__input-time{
            margin-left: 0;
            margin-top: 10px;
        }

        .add-entry__dead-input{
            flex-wrap: wrap;
        }

        .add-entry__dead-time--bottom{
            margin-top: 10px;
        }
    }

</style>